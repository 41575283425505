import NProgress from "nprogress";
import { Switch, Route, Redirect } from "react-router-dom";
import { Suspense, Fragment, lazy, useEffect, useMemo } from "react";
// material
import { makeStyles } from "@material-ui/core/styles";
// guards
import GuestGuard from "../guards/GuestGuard";
// components
import LoadingScreen from "../components/LoadingScreen";
//
import AuthGuard from "../guards/AuthGuard";
// layouts
import DashboardLayout from "../layouts/dashboard";
import HomeLayout from "../layouts/home";
import {
  PATH_PAGE,
  PATH_AUTH,
  PATH_DASHBOARD,
  mode,
  folderName,
} from "./paths";

// ----------------------------------------------------------------------

const nprogressStyle = makeStyles((theme) => ({
  "@global": {
    "#nprogress": {
      pointerEvents: "none",
      "& .bar": {
        top: 0,
        left: 0,
        height: 2,
        width: "100%",
        position: "fixed",
        zIndex: theme.zIndex.snackbar,
        backgroundColor: theme.palette.primary.main,
        boxShadow: `0 0 2px ${theme.palette.primary.main}`,
      },
      "& .peg": {
        right: 0,
        opacity: 1,
        width: 100,
        height: "100%",
        display: "block",
        position: "absolute",
        transform: "rotate(3deg) translate(0px, -4px)",
        boxShadow: `0 0 10px ${theme.palette.primary.main}, 0 0 5px ${theme.palette.primary.main}`,
      },
    },
  },
}));

function RouteProgress(props) {
  nprogressStyle();

  NProgress.configure({
    speed: 500,
    showSpinner: false,
  });

  useMemo(() => {
    NProgress.start();
  }, []);

  useEffect(() => {
    NProgress.done();
  }, []);

  return <Route {...props} />;
}

export function renderRoutes(routes = []) {
  return (
    <Suspense fallback={<LoadingScreen />}>
      <Switch>
        {routes.map((route, idx) => {
          const Component = route.component;
          const Guard = route.guard || Fragment;
          const Layout = route.layout || Fragment;

          return (
            <RouteProgress
              key={`routes-${idx}`}
              path={route.path}
              exact={route.exact}
              render={(props) => (
                <Guard>
                  <Layout>
                    {route.routes ? (
                      renderRoutes(route.routes)
                    ) : (
                      <Component {...props} />
                    )}
                  </Layout>
                </Guard>
              )}
            />
          );
        })}
      </Switch>
    </Suspense>
  );
}

const routes = [
  // Others Routes
  {
    exact: true,
    guard: GuestGuard,
    path: PATH_AUTH.login,
    component: lazy(() => import("../views/authentication/Login")),
  },
  {
    exact: true,
    path: PATH_AUTH.loginUnprotected,
    component: lazy(() => import("../views/authentication/Login")),
  },
  {
    exact: true,
    guard: GuestGuard,
    path: PATH_AUTH.register,
    component: lazy(() => import("../views/authentication/Register")),
  },
  {
    exact: true,
    guard: GuestGuard,
    path: PATH_AUTH.confirmPassword,
    component: lazy(() => import("../views/authentication/ConfirmPassword")),
  },
  {
    exact: true,
    path: PATH_AUTH.registerUnprotected,
    component: lazy(() => import("../views/authentication/Register")),
  },
  {
    exact: true,
    path: PATH_AUTH.resetPassword,
    component: lazy(() => import("../views/authentication/ResetPassword")),
  },
  {
    exact: true,
    path: PATH_AUTH.verify,
    component: lazy(() => import("../views/authentication/VerifyCode")),
  },
  {
    exact: true,
    path: PATH_PAGE.page404,
    component: lazy(() => import("../views/general/Page404")),
  },
  {
    exact: true,
    path: PATH_PAGE.page500,
    component: lazy(() => import("../views/general/Page500")),
  },
  {
    exact: true,
    path: PATH_PAGE.maintenance,
    component: lazy(() => import("../views/general/Maintenance")),
  },
  {
    exact: true,
    path: PATH_AUTH.root,
    component: () => <Redirect to={PATH_AUTH.login} />,
  },
  {
    path: PATH_DASHBOARD.root,
    guard: AuthGuard,
    layout: DashboardLayout,
    routes: [
      {
        exact: true,
        path: PATH_DASHBOARD.root,
        component: () => <Redirect to={PATH_DASHBOARD.general.app} />,
      },
      {
        exact: true,
        path: PATH_DASHBOARD.user.account,
        component: () => <Redirect to={PATH_DASHBOARD.general.app} />,
      },
      {
        exact: true,
        path: PATH_DASHBOARD.general.app,
        component: lazy(() => import("../views/dashboard/DashboardContent")),
      },
      {
        exact: true,
        path: PATH_DASHBOARD.application,
        component: lazy(() => import("../views/application/Application")),
      },
      {
        exact: true,
        path: PATH_DASHBOARD.menu,
        component: lazy(() => import("../views/menu/Menu")),
      },
      {
        exact: true,
        path: PATH_DASHBOARD.action,
        component: lazy(() => import("../views/action/Action")),
      },
      {
        exact: true,
        path: PATH_DASHBOARD.role,
        component: lazy(() => import("../views/role/Role")),
      },
      {
        exact: true,
        path: PATH_DASHBOARD.user.root,
        component: lazy(() => import("../views/user/User")),
      },
      {
        exact: true,
        path: PATH_DASHBOARD.parameter,
        component: lazy(() => import("../views/parameter/Parameter")),
      },
      {
        exact: true,
        path: PATH_DASHBOARD.unit,
        component: lazy(() => import("../views/unit/Unit")),
      },
      {
        exact: true,
        path: PATH_DASHBOARD.student,
        component: lazy(() => import("../views/student/Student")),
      },
      {
        exact: true,
        path: PATH_DASHBOARD.studentHumas,
        component: lazy(() => import("../views/studentHumas")),
      },
      {
        exact: true,
        path: PATH_DASHBOARD.studentHumasDetail,
        component: lazy(() => import("../views/studentHumasDetail")),
      },
      {
        exact: true,
        path: PATH_DASHBOARD.studentEdit,
        component: lazy(() => import("../views/student/StudentForm")),
      },
      {
        exact: true,
        path: PATH_DASHBOARD.employee,
        component: lazy(() => import("../views/employee/Employee")),
      },
      {
        exact: true,
        path: PATH_DASHBOARD.parent,
        component: lazy(() => import("../views/parent/Parent")),
      },
      {
        exact: true,
        path: PATH_DASHBOARD.jenjang,
        component: lazy(() => import("../views/jenjang/Jenjang")),
      },
      {
        exact: true,
        path: PATH_DASHBOARD.school,
        component: lazy(() => import("../views/school/School")),
      },
      {
        exact: true,
        path: PATH_DASHBOARD.kelas,
        component: lazy(() => import("../views/kelas/Kelas")),
      },
      {
        exact: true,
        path: PATH_DASHBOARD.jurusan,
        component: lazy(() => import("../views/jurusan/Jurusan")),
      },
      {
        exact: true,
        path: PATH_DASHBOARD.parallel,
        component: lazy(() => import("../views/parallel/Parallel")),
      },
      {
        exact: true,
        path: PATH_DASHBOARD.tahunPelajaran,
        component: lazy(() => import("../views/tahun-pelajaran/index")),
      },
      {
        exact: true,
        path: PATH_DASHBOARD.list_ekskul,
        component: lazy(() => import("../views/ekskul/ListEkskul")),
      },
      {
        exact: true,
        path: PATH_DASHBOARD.ekskul,
        component: lazy(() => import("../views/ekskul")),
      },
      {
        exact: true,
        path: PATH_DASHBOARD.laporanPenilaian,
        component: lazy(() => import("../views/laporan/index")),
      },
      {
        exact: true,
        path: PATH_DASHBOARD.laporanPenilaianDetail,
        component: lazy(() => import("../views/laporan/Penilaian")),
      },
      {
        exact: true,
        path: PATH_DASHBOARD.profile,
        component: lazy(() => import("../views/profile/Profile")),
      },
      {
        exact: true,
        path: PATH_DASHBOARD.absen,
        component: lazy(() => import("../views/absen/Absen")),
      },
      {
        exact: true,
        path: PATH_DASHBOARD.category,
        component: lazy(() => import("../views/category/Category")),
      },
      {
        exact: true,
        path: PATH_DASHBOARD.user_access_setting,
        component: lazy(() => import("../views/user_access_setting")),
      },
      {
        exact: true,
        path: PATH_DASHBOARD.nilai.formatif,
        component: lazy(() => import("../views/nilai/formatif")),
      },
      {
        exact: true,
        path: PATH_DASHBOARD.nilai.sumatif,
        component: lazy(() => import("../views/nilai/sumatif")),
      },
      {
        exact: true,
        path: PATH_DASHBOARD.nilai.formatif_detail,
        component: lazy(() => import("../views/nilai/formatif/Parent")),
      },
      {
        exact: true,
        path: PATH_DASHBOARD.nilai.sumatif_detail,
        component: lazy(() => import("../views/nilai/sumatif/Parent")),
      },
      {
        exact: true,
        path: PATH_DASHBOARD.rapor.sumatif_formatif,
        component: lazy(() => import("../views/rapor/sumatif_formatif")),
      },
      {
        exact: true,
        path: PATH_DASHBOARD.rapor.sumatif_formatif_detail,
        component: lazy(() => import("../views/rapor/sumatif_formatif/Parent")),
      },
      {
        exact: true,
        path: PATH_DASHBOARD.mapelPilihanSiswa.root,
        component: lazy(() => import("../views/mapel-pilihan-siswa/Index")),
      },
      {
        exact: true,
        path: PATH_DASHBOARD.list_ekskul,
        component: lazy(() => import("../views/ekskul/ListEkskul")),
      },
      {
        exact: true,
        path: PATH_DASHBOARD.gallery,
        component: lazy(() => import("../views/gallery")),
      },
      {
        exact: true,
        path: PATH_DASHBOARD.information_user,
        component: lazy(() => import("../views/information_user")),
      },
      {
        exact: true,
        path: PATH_DASHBOARD.gallery_user,
        component: lazy(() => import("../views/gallery_user")),
      },
      {
        exact: true,
        path: PATH_DASHBOARD.ekskul,
        component: lazy(() => import("../views/ekskul")),
      },
      {
        exact: true,
        path: PATH_DASHBOARD.category,
        component: lazy(() => import("../views/category/Category")),
      },
      {
        // create by Eko 10 Nov.23
        exact: true,
        path: PATH_DASHBOARD.pengaturan_buku_panduan,
        component: lazy(() => import("../views/buku_panduan/BukuPanduan")),
      },
      {
        //create Eko 27 Agt 2024
        exact: true,
        path: PATH_DASHBOARD.birthdayGreetings,
        component: lazy(() => import("../views/birthday-greetings/index")),
      },

      {
        exact: true,
        path: PATH_DASHBOARD.materi_pembelajaran,
        component: lazy(() => import("../views/materi_pembelajaran")),
      },
      {
        exact: true,
        path: PATH_DASHBOARD.materi_pembelajaran_detail,
        component: lazy(() => import("../views/materi_pembelajaran/Materi")),
      },
      {
        exact: true,
        path: PATH_DASHBOARD.setting_kasi,
        component: lazy(() => import("../views/setting_kasi")),
      },
      {
        exact: true,
        path: PATH_DASHBOARD.rapat_umum,
        component: lazy(() => import("../views/rapat_umum")),
      },
      {
        exact: true,
        path: PATH_DASHBOARD.generate_password,
        component: lazy(() => import("../views/generate_password")),
      },
      {
        exact: true,
        path: PATH_DASHBOARD.calendar,
        component: lazy(() => import("../views/calendar/Calendar")),
      },
      {
        exact: true,
        path: PATH_DASHBOARD.kesenian,
        component: lazy(() => import("../views/pemilihan_kesenian")),
      },
      {
        exact: true,
        path: PATH_DASHBOARD.list_kesenian,
        component: lazy(() =>
          import("../views/pemilihan_kesenian/ListKesenian")
        ),
      },
      {
        exact: true,
        path: PATH_DASHBOARD.keterampilan,
        component: lazy(() => import("../views/pemilihan_keterampilan")),
      },
      {
        exact: true,
        path: PATH_DASHBOARD.list_keterampilan,
        component: lazy(() =>
          import("../views/pemilihan_keterampilan/ListKeterampilan")
        ),
      },
      {
        exact: true,
        path: PATH_DASHBOARD.jadwal_pelajaran,
        component: lazy(() => import("../views/jadwal_pelajaran")),
      },
      {
        exact: true,
        path: PATH_DASHBOARD.jadwal_pelajaran_detail,
        component: lazy(() =>
          import("../views/jadwal_pelajaran/LaporanJadwalPelajaran")
        ),
      },
      {
        exact: true,
        path: PATH_DASHBOARD.rapat_umum,
        component: lazy(() => import("../views/rapat_umum")),
      },
      {
        exact: true,
        path: PATH_DASHBOARD.supervisi.setelan,
        component: lazy(() => import("../views/supervisi-setelan")),
      },
      {
        exact: true,
        path: PATH_DASHBOARD.supervisi.point_setting,
        component: lazy(() => import("../views/supervisi-point-setting")),
      },
      {
        exact: true,
        path: PATH_DASHBOARD.supervisi.alokasi,
        component: lazy(() => import("../views/supervisi-alokasi")),
      },
      {
        exact: true,
        path: PATH_DASHBOARD.supervisi.penilaian_approval,
        component: lazy(() => import("../views/supervisi-penilaian-approval")),
      },
      {
        exact: true,
        path: PATH_DASHBOARD.supervisi.penilaian,
        component: lazy(() => import("../views/supervisi-penilaian")),
      },
      {
        exact: true,
        path: PATH_DASHBOARD.supervisi.report,
        component: lazy(() => import("../views/supervisi-report")),
      },
      {
        exact: true,
        path: PATH_DASHBOARD.supervisi.report_guru,
        component: lazy(() => import("../views/supervisi-report-guru")),
      },
      {
        exact: true,
        path: PATH_DASHBOARD.predikat_nilai,
        component: lazy(() => import("../views/predikat-nilai")),
      },
      {
        exact: true,
        path: PATH_DASHBOARD.tugas_siswa,
        component: lazy(() => import("../views/tugas-siswa")),
      },
      {
        exact: true,
        path: PATH_DASHBOARD.jadwal_ulangan_harian,
        component: lazy(() => import("../views/jadwal-ulangan/harian")),
      },
      {
        exact: true,
        path: PATH_DASHBOARD.jadwal_ulangan_pas,
        component: lazy(() => import("../views/jadwal-ulangan/pas")),
      },
      {
        exact: true,
        path: PATH_DASHBOARD.announcement,
        // component: lazy(() => import("../views/announcement")),
        component: lazy(() => import("../views/announcement/Announcement")),
      },
      {
        exact: true,
        path: PATH_DASHBOARD.information,
        component: lazy(() => import("../views/information")),
      },
      {
        component: () => <Redirect to="/404" />,
      },
    ],
  },
  {
    path: "*",
    layout: HomeLayout,
    routes: [
      {
        exact: true,
        path: mode === "LOCAL" ? "/" : `/${folderName}`,
        component: () => (
          <Redirect
            to={mode === "LOCAL" ? "/dashboard" : `/${folderName}/dashboard`}
          />
        ),
      },
      // ----------------------------------------------------------------------

      {
        component: () => <Redirect to="/404" />,
      },
    ],
  },
];

export default routes;
