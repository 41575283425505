import axios from "axios";
// ----------------------------------------------------------------------

export const axiosInstance = axios.create();

if (process.env.REACT_APP_BACKEND_MODE === "PROD") {
  axiosInstance.defaults.baseURL = process.env.REACT_APP_API_PROD;
} else if (process.env.REACT_APP_BACKEND_MODE === "UAT") {
  axiosInstance.defaults.baseURL = process.env.REACT_APP_API_UAT;
} else {
  axiosInstance.defaults.baseURL = process.env.REACT_APP_API_LOCAL;
}

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) =>
    Promise.reject(
      (error.response && error.response.data) || "Something went wrong"
    )
);

export const endpoint = {
  employee: {
    root: "/employee",
    option: "/employee/option",
    employeeHris: "/employee-hris",
    byUnit: "/employee-by-unit",
    byRole: "employee-by-role",
    birthday: "/employeebday",
  },
  guru: {
    tugas: "/tugas-guru",
    birthday: "/employeebday",
  },
  jenjang: {
    root: "/jenjang",
    option: "/jenjang/option",
  },
  jurusan: {
    root: "/jurusan",
    sync: "/jurusan/sync",
    option: "/jurusan/option",
  },
  kelas: {
    root: "/kelas",
    sync: "/kelas/sync",
    option: "/kelas/option",
  },
  login: "login",
  register: "register",
  forgot_password: "forgot-password",
  change_password: "change-password",
  user: {
    root: "user",
    option: "/user/option",
    by_token: "user/by-token",
    role: "user/role",
    change_password: "user/change-password",
    reset_password: "user/reset-password",
    ofRole: "/users/of-role",
    by_role: "user-by-role",
    generate_password: "generate-password",
    download_generate_password: "download-generate-password",
    check_username_password: "check-username-password",
  },
  parameter: {
    root: "/parameter",
    option: "/parameter/option",
  },
  application: {
    root: "/application",
    option: "/application/option",
    role: "/application/role",
    menu: "/application/menu",
  },
  menu: {
    root: "/menu",
    option: "/menu/option",
    action: "/menu/action",
  },
  action: {
    root: "/action",
    option: "/action/option",
    approver: "/action/approver",
    role: "/action/role",
  },
  parallel: {
    root: "/parallel",
    sync: "/parallel/sync",
    option: "/parallel/option",
  },
  parent: {
    root: "/parent",
    option: "/parent/option",
    student: "/parent/student",
    studentdetail: "/parent/student-detail",
  },
  role: {
    root: "/role",
    detail: "/role/id",
    option: "/role/option",
    application: "/role/application",
    priviledge: "/role/priviledge",
    user: "/role/user",
    approval: "/role/approval",
    userInsertByPosition: "/role/byPosition",
  },
  school: {
    root: "/school",
    option: "/school/option",
  },
  position: {
    byUnit: "/postion/byUnitId",
    getOccupaions: "/position/occupations",
  },
  student: {
    root: "/student",
    sync: "/student/sync",
    option: "/student/option",
    parent: "/student/parent",
    mutation: "/student/mutation",
    sibling: "/student/sibling",
    addSibling: "/student-siblings",
    detail: "/student-detail",
    presentance: "student-presence",
    reqParent: "student-presence/req-parent",
    studentPresenceAttachment: "student-presence-attachment",
    by_announcement: "student-by-announcement",
    humas: "student-humas",
    sibling_family: "student-sibling-family",
    presence_request: "presence-request",
    conselling: "student-conselling",
    consellings: "student-consellings",
    conselling_print: "student-conselling-print",
    incident: "student-incident-note",
    incident_print: "student-incident-note-print",
    performance: "student-performance",
    birthday: "/student/birthday",
  },
  tahun_pelajaran: {
    root: "/tahun-pelajaran",
    option: "/tahun-pelajaran/option",
  },
  semester: {
    root: "/semester",
    option: "/semester/option",
  },
  term: {
    option: "/term/option",
  },
  indicator_allocation: {
    aspect_option: "/indicator_aspect_allocation/aspect/option",
    indicator_option: "/indicator_aspect_allocation/indicator/option",
  },
  weekly_assesment: {
    report: {
      by_student_to_xls: "/weekly_assesment/report/by_student/xls",
      parent: "/weekly_assesment/report/parent",
      parent_to_xls: "/weekly_assesment/report/parent/xls",
    },
  },
  assesment_category: {
    option: "/assesment_category/option",
  },
  unit: {
    root: "/unit",
    option: "/unit/option",
    unitWithClass: "/unit-kelas",
  },
  employeeUnit: {
    root: "/employeeUnit",
    option: "/employeeUnit/option",
  },
  notification: {
    root: "/notification",
    totalunread: "/notification/total-unread",
    update: "/notification/update",
  },
  category: {
    root: "/category",
  },
  applicationCategory: {
    root: "/application-categories",
  },
  ekskul: {
    root: "ekskul",
    category: "ekskul_category",
    detail: "ekskul_detail",
    choose: "ekskul-choose",
    cancel: "ekskul-cancel",
  },
  kesenian: {
    mapel: "mapel-kesenian",
    root: "kesenian",
    choose: "kesenian-choose",
    cancel: "kesenian-cancel",
    acc: "kesenian-acc",
  },
  keterampilan: {
    mapel: "mapel-keterampilan",
    root: "keterampilan",
    choose: "keterampilan-choose",
    cancel: "keterampilan-cancel",
    acc: "keterampilan-acc",
  },
  user_access_setting: "user-access-jenjang",
  intrakurikuler: {
    tujuan_pembelajaran: {
      root: "tujuan-pembelajaran",
      mapel: "tujuan-pembelajaran-mapel",
    },
    ruang_lingkup_materi: {
      root: "ruang-lingkup-materi",
      mapel: "ruang-lingkup-materi-mapel",
    },
  },
  daftarmapelpilihan: {
    getmapelpilihan: "/mapelpilihan",
    gettahunmapelpilihan: "/tahunmapelpilihanlast",
    getjadwal: "/jadwal-mapel-pilihan",
  },
  rapor: {
    siswa: "rapor-siswa",
    generate_mid: "rapor-mid-siswa-generate",
    generate: "rapor-siswa-generate",
  },
  mapelpilihansiswa: {
    store: "/store-mapel-pilihan-siswa",
    update: "/update-mapel-pilihan-siswa",
    getmapelinputsiswa: "/get-pelajaran-input-siswa",
    approvalparent: "approval-parent-mapel-pilihan-siswa",
    cetakpdf: "/cetak-mapel-pilihan-siswa-pdf",
  },
  announcement: {
    root: "/announcement",
  },
  information: {
    root: "/information",
    detail: "/information-detail",
    attachment: "/information-attachment",
    byUser: "/information-byuser",
    updateRead: "/information-update-read",
  },
  gallery: {
    root: "/gallery",
    detail: "/gallery-detail",
    attachment: "/gallery-attachment",
    byUser: "/gallery-byuser",
    updateRead: "/gallery-update-read",
  },
  download_image: "download-image",
  materi: {
    root: "/student-modul",
  },
  rapat_rumpun: {
    byuser: "/rapat-rumpun-byuser",
  },
  rapat_umum: {
    root: "/rapat-umum",
    jenis: "/rapat-umum-type",
    pembahasan: "/rapat-umum-pembahasan",
    byuser: "/rapat-umum-byuser",
  },
  setting_kasi: {
    root: "/setting-kasi",
  },
  mapels: {
    root: "/mapels",
  },
  setting_buka_tutup: {
    root: "/setting-buka-tutup",
  },
  bukuPanduan: {
    root: "/buku-panduan",
    student: "/buku-panduan/student",
    formPernyataanList: "/buku-panduan/formPernyataan",
    formPernyataan: "/buku-panduan/uploadFormPernyataan",
    updateFormPernyataan: "/buku-panduan/updateFormPernyataan",
  },
  calendar: {
    root: "calendar",
    option: "calendar/option",
    update: "calendar",
    delete: "calendar/delete",
    report2pdf: "calendar_pdf",
  },
  unitCalendar: {
    option: "unitcalendar/option",
  },
  jadwalpelajaran: {
    jamkelas: "jadwal/jamkelas",
    jadwalkelas: "jadwal_pelajaran/per_kelas",
    upload: "jadwal-pelajaran-upload",
  },
  parameter_jadwal: {
    root: "parameter_jadwal",
  },
  laporan_jadwal_pelajaran: {
    report: "jadwal_pelajaran_kelas_pdf",
    download: "jadwal-pelajaran-download",
  },
  penjadwalan_pengisian: {
    root: "penjadwalan-pengisian",
  },
  perusahaan: {
    root: "/perusahaan",
    option: "/perusahaan/option",
  },
  supervisi: {
    category: "supervisi-category",
    category_delete: "supervisi-category-delete",
    choose_type: "supervisi-choose-type",
    choose_type_delete: "supervisi-choose-type-delete",
    value_type: "supervisi-value-type",
    value_type_delete: "supervisi-value-type-delete",
    value_config: "supervisi-value-config",
    value_config_delete: "supervisi-value-config-delete",
    point_type: "supervisi-point-type",
    point_type_delete: "supervisi-point-type-delete",
    point: "supervisi-point",
    point_delete: "supervisi-point-delete",
    template: "supervisi-template",
    template_delete: "supervisi-template-delete",
    template_active: "supervisi-template-active",
    alokasi: "supervisi-alokasi",
    report: "supervisi-report",
    report_download: "supervisi-report-download",
    reschedule: "supervisi-reschedule",
    nilai: "supervisi-nilai",
    nilai_approval: "supervisi-nilai-approval",
    penilai: "supervisi-penilai",
  },
  predikat: {
    master: "predikat-nilai",
  },
  blastMail: {
    root: "/blastmail",
    kategori: "/blastmail/blastmkategori",
    tipe: "/blastmail/blastmtipe",
    emailpengirim: "/blastmail/blastmemail",
    blasttemplate: "/blastmail/blasttemplate",
    template: "/blastmailtemplate",
    template_edit: "/blastmailtemplate_edit",
    kirim_email: "send-greeting",
  },
  tugas: {
    root: "tugas-siswa",
    upload: "tugas-siswa-upload",
  },
  jadwal_ulangan: {
    harian: "jadwal-ulangan-harian",
    pas: "jadwal-ulangan-pas",
    tanggal_pas: "tanggal-ulangan-pas",
  },
};
