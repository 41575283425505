// ----------------------------------------------------------------------

function path(root, sublink) {
  return `${root}${sublink}`;
}

export const mode = process.env.REACT_APP_BACKEND_MODE;
export const folderName = process.env.REACT_APP_FOLDER_NAME;

const ROOTS_AUTH = mode === "LOCAL" ? "/auth" : `/${folderName}/auth`;
const ROOTS_DASHBOARD =
  mode === "LOCAL" ? "/dashboard" : `/${folderName}/dashboard`;

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, "/login"),
  loginUnprotected: path(ROOTS_AUTH, "/login-unprotected"),
  register: path(ROOTS_AUTH, "/register"),
  registerUnprotected: path(ROOTS_AUTH, "/register-unprotected"),
  resetPassword: path(ROOTS_AUTH, "/reset-password"),
  verify: path(ROOTS_AUTH, "/verify"),
  confirmPassword: path(ROOTS_AUTH, "/confirm-password/:code"),
};

export const PATH_PAGE = {
  comingSoon: "/coming-soon",
  maintenance: "/maintenance",
  pricing: "/pricing",
  payment: "/payment",
  page404: "/404",
  page500: "/500",
};

export const PATH_HOME = {
  cloud: "https://www.sketch.com/s/0fa4699d-a3ff-4cd5-a3a7-d851eb7e17f0",
  purchase: "https://material-ui.com/store/items/minimal-dashboard/",
  components: "/components",
  dashboard: ROOTS_DASHBOARD,
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  general: {
    app: path(ROOTS_DASHBOARD, "/app"),
    ecommerce: path(ROOTS_DASHBOARD, "/ecommerce"),
    analytics: path(ROOTS_DASHBOARD, "/analytics"),
  },
  user: {
    root: path(ROOTS_DASHBOARD, "/user"),
    profile: path(ROOTS_DASHBOARD, "/user/profile"),
    cards: path(ROOTS_DASHBOARD, "/user/card"),
    list: path(ROOTS_DASHBOARD, "/user/list"),
    account: path(ROOTS_DASHBOARD, "/user/account"),
  },
  application: path(ROOTS_DASHBOARD, "/application"),
  menu: path(ROOTS_DASHBOARD, "/menu"),
  action: path(ROOTS_DASHBOARD, "/action"),
  role: path(ROOTS_DASHBOARD, "/role"),
  parameter: path(ROOTS_DASHBOARD, "/parameter"),
  unit: path(ROOTS_DASHBOARD, "/unit"),
  student: path(ROOTS_DASHBOARD, "/student"),
  studentEdit: path(ROOTS_DASHBOARD, "/student/edit/:niy"),
  studentHumas: path(ROOTS_DASHBOARD, "/student-humas"),
  studentHumasDetail: path(ROOTS_DASHBOARD, "/student-humas/detail/:niy"),
  employee: path(ROOTS_DASHBOARD, "/employee"),
  parent: path(ROOTS_DASHBOARD, "/parent"),
  jenjang: path(ROOTS_DASHBOARD, "/jenjang"),
  school: path(ROOTS_DASHBOARD, "/school"),
  kelas: path(ROOTS_DASHBOARD, "/kelas"),
  jurusan: path(ROOTS_DASHBOARD, "/jurusan"),
  parallel: path(ROOTS_DASHBOARD, "/parallel"),
  tahunPelajaran: path(ROOTS_DASHBOARD, "/tahun-pelajaran"),
  birthdayGreetings: path(ROOTS_DASHBOARD, "/birthday-greetings"),
  profile: path(ROOTS_DASHBOARD, "/profile"),
  ekskul: path(ROOTS_DASHBOARD, "/ekskul"),
  list_ekskul: path(ROOTS_DASHBOARD, "/list-ekskul"),
  category: path(ROOTS_DASHBOARD, "/category-application"),
  absen: path(ROOTS_DASHBOARD, "/absen-siswa"),
  user_access_setting: path(ROOTS_DASHBOARD, "/user-access-setting"),
  nilai: {
    formatif: path(ROOTS_DASHBOARD, "/nilai-formatif"),
    sumatif: path(ROOTS_DASHBOARD, "/nilai-sumatif"),
    sumatif_detail: path(ROOTS_DASHBOARD, "/nilai-sumatif-detail"),
    formatif_detail: path(ROOTS_DASHBOARD, "/nilai-formatif-detail"),
  },
  mapelPilihanSiswa: {
    root: path(ROOTS_DASHBOARD, "/mapel-pilihan-siswa"),
  },
  rapor: {
    sumatif_formatif: path(ROOTS_DASHBOARD, "/rapor-sumatif-formatif"),
    sumatif_formatif_detail: path(
      ROOTS_DASHBOARD,
      "/rapor-sumatif-formatif-detail"
    ),
  },
  information: path(ROOTS_DASHBOARD, "/information"),
  gallery: path(ROOTS_DASHBOARD, "/gallery"),
  information_user: path(ROOTS_DASHBOARD, "/information-user"),
  gallery_user: path(ROOTS_DASHBOARD, "/gallery-user"),
  category: path(ROOTS_DASHBOARD, "/category-application"),
  student_ekskul: path(ROOTS_DASHBOARD, "/ekskul"),
  list_ekskul: path(ROOTS_DASHBOARD, "/list-ekskul"),
  absen: path(ROOTS_DASHBOARD, "/absen-siswa"),
  announcement: path(ROOTS_DASHBOARD, "/announcement"),
  laporanPenilaian: path(ROOTS_DASHBOARD, "/laporan-penilaian"),
  laporanPenilaianDetail: path(ROOTS_DASHBOARD, "/laporan-penilaian-detail"),
  materi_pembelajaran: path(ROOTS_DASHBOARD, "/materi-pembelajaran"),
  materi_pembelajaran_detail: path(
    ROOTS_DASHBOARD,
    "/materi-pembelajaran-detail"
  ),
  setting_kasi: path(ROOTS_DASHBOARD, "/setting-kasi"),
  rapat_umum: path(ROOTS_DASHBOARD, "/rapat-umum"),
  kesenian: path(ROOTS_DASHBOARD, "/kesenian"),
  list_kesenian: path(ROOTS_DASHBOARD, "/list-kesenian"),
  keterampilan: path(ROOTS_DASHBOARD, "/keterampilan"),
  list_keterampilan: path(ROOTS_DASHBOARD, "/list-keterampilan"),
  //create by Eko 10 Nov.23
  pengaturan_buku_panduan: path(ROOTS_DASHBOARD, "/buku-panduan-siswa"),
  generate_password: path(ROOTS_DASHBOARD, "/generate-password"),
  calendar: path(ROOTS_DASHBOARD, "/calendar"),
  jadwal_pelajaran: path(ROOTS_DASHBOARD, "/jadwal-pembelajaran"),
  jadwal_pelajaran_detail: path(ROOTS_DASHBOARD, "/jadwal-pembelajaran-detail"),
  supervisi: {
    setelan: path(ROOTS_DASHBOARD, "/supervisi-master-setting"),
    point_setting: path(ROOTS_DASHBOARD, "/supervisi-point-setting"),
    alokasi: path(ROOTS_DASHBOARD, "/supervisi-alokasi"),
    penilaian: path(ROOTS_DASHBOARD, "/supervisi-penilaian"),
    penilaian_approval: path(ROOTS_DASHBOARD, "/supervisi-penilaian-approval"),
    report: path(ROOTS_DASHBOARD, "/supervisi-report"),
    report_guru: path(ROOTS_DASHBOARD, "/supervisi-report-guru"),
  },
  predikat_nilai: path(ROOTS_DASHBOARD, "/predikat-nilai"),
  tugas_siswa: path(ROOTS_DASHBOARD, "/tugas-siswa"),
  jadwal_ulangan_harian: path(ROOTS_DASHBOARD, "/jadwal-ulangan-harian"),
  jajwal_ulangan_pas: path(ROOTS_DASHBOARD, "/jadwal-ulangan-pas"),
};
